import React, { useState } from "react";
import Head from "next/head";
import Aside from "components/Aside";
import { APP_PAGE_TITLE_POSTFIX } from "constants/index";
import Header from "../../Header";
import { useThemeContext } from "../../../store";
import { useAuth } from "hooks/use-auth";
import { TabLayout } from "./TabNav/TabLayout";
import { ImpersonatedUserBar } from "./ImpersonatedUserBar";

type LayoutProps = { children?: React.ReactNode; title?: string };

const LayoutV2 = ({ children, title }: LayoutProps): JSX.Element => {
    const { mainContainerRef } = useThemeContext();

    const [navbarOpen, setNavbarOpen] = useState(false);

    return (
        <>
            <Head>
                <title>{title ? `${title} - ${APP_PAGE_TITLE_POSTFIX}` : ""}</title>
            </Head>
            {/* Y-CONTAINER: Children (other than X-CONTAINER) should be full viewport width and have fixed height. They will layout in order top to bottom. All must have class flex-grow-0 and flex-shrink-0 classes applied. */}
            <div className="h-dvh flex flex-col">
                {/* FULL-WIDTH HEADERS */}
                <ImpersonatedUserBar />
                <Header showNavbar={() => setNavbarOpen(true)} layoutV2 />
                {/* X-CONTAINER: Container will grow vertically to leverage all space not used by headers and footers. Children should have height that leverages all vertically available space (use h-full to do so) and have fixed width. They will layout left to right. */}
                <div className="flex flex-grow overflow-hidden">
                    {/* LEFT SIDEBAR(S) */}
                    <Aside isOpen={navbarOpen} hideNavbar={() => setNavbarOpen(false)} layoutV2 />
                    {/* MAIN CONTENT: Container will grow horizontally to leverage all space not used by left and right sidebars. */}
                    <div className="flex flex-grow overflow-auto" ref={mainContainerRef}>
                        {children}
                    </div>
                    {/* RIGHT SIDEBAR(S) [currently none] */}
                </div>
                {/* FULL-WIDTH FOOTERS  [currently none] */}
            </div>
        </>
    );
};

export default function Layout(props: LayoutProps) {
    const { isFeatureEnabled } = useAuth();
    return isFeatureEnabled("nav_v3") ? <TabLayout {...props} /> : <LayoutV2 {...props} />;
}
