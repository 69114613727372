import React, { HTMLAttributes, ReactNode } from "react";
import classNames from "classnames";
import Layout from "components/common/v2/Layout";
import { TabLayout } from "./TabNav/TabLayout";
import SystemBanners from "components/SystemBanners";
import { HeaderBannerLayoutProps } from "../v1/HeaderBannerLayout";
import { useAuth } from "hooks/use-auth";

interface PageTitleProps extends HTMLAttributes<HTMLHeadingElement> {
    navV3?: boolean;
}

/** The title text for a portal page */
export const PageTitle = ({ navV3, ...props }: PageTitleProps): JSX.Element => (
    <h1
        {...props}
        className={classNames(
            "font-medium",
            !navV3
                ? "text-displayLargeMobile lg:text-displayLarge text-main"
                : "text-headerFS24 text-primary",
            props.className
        )}
    />
);

export default function HeaderBannerLayout({
    children,
    title,
    headerText,
    rightOfHeaderContent,
    noPadding = false,
    breadcrumbs
}: HeaderBannerLayoutProps & {
    rightOfHeaderContent?: ReactNode;
    breadcrumbs?: ReactNode;
}) {
    const { isFeatureEnabled } = useAuth();
    const displayTitle = headerText ?? title;

    if (isFeatureEnabled("nav_v3")) {
        return (
            <TabLayout title={title} className={noPadding ? "" : "px-4 tablet:px-0 tablet:py-6"}>
                <SystemBanners />
                {breadcrumbs && <div className="mb-4">{breadcrumbs}</div>}
                {(displayTitle || rightOfHeaderContent) && (
                    <div className="w-full flex-shrink-0 flex items-end justify-between mb-4 tablet:mb-6">
                        {displayTitle && <PageTitle navV3>{displayTitle}</PageTitle>}
                        {rightOfHeaderContent}
                    </div>
                )}
                {children}
            </TabLayout>
        );
    }

    return (
        <Layout title={title}>
            <div
                className={`w-full flex flex-col flex-grow ${
                    noPadding ? "" : "p-6 md:py-12 md:px-16"
                }`}
            >
                <div className="w-full container-1440 flex flex-col flex-grow">
                    <SystemBanners />
                    {breadcrumbs && <div className="mb-4">{breadcrumbs}</div>}
                    {(displayTitle || rightOfHeaderContent) && (
                        <div className="w-full flex-shrink-0 flex items-end justify-between mb-4 md:mb-6">
                            {displayTitle && <PageTitle>{displayTitle}</PageTitle>}
                            {rightOfHeaderContent}
                        </div>
                    )}
                    {children}
                </div>
            </div>
        </Layout>
    );
}
